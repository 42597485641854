<template>
  <div class="h-100 d-flex">
    <b-container class="bg-white flex-fill p-2">
      <router-link
        :to="{ name: 'settings' }"
        class="btn btn-outline-primary btn-sm"
      >
        <font-awesome-icon icon="arrow-left" />
      </router-link>
      <b-form class="py-2" @submit.prevent="onSubmit">
        <b-form-group label="Mon email" label-for="email">
          <b-form-input
            id="email"
            v-model="me.email"
            type="email"
            required
            placeholder="Mon email"
          />
          <b-form-invalid-feedback v-if="error.email" :state="false">
            {{ error.email }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-btn
          type="submit"
          class="mt-2"
          variant="primary"
          block
          :disabled="loading"
        >
          <loader v-if="loading" size="1x" />Valider
        </b-btn>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import Profile from "@/mixins/profile";
export default {
  mixins: [Profile],
  methods: {
    onSubmit() {
      this.submit({
        email: this.me.email,
      });
    },
  },
};
</script>
